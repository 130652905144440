import React, { useState } from 'react';

import Github from '../../../src/img/github.svg';
import Linkedin from '../../../src/img/linkedin.svg';
import DocDownload from '../../../src/img/doc-download.svg';
import responsiveMenuIcon from '../../../src/img/responsive-menu.svg';

// Importa os estilos específicos do componente
import './menu.modules.scss';

import SwitchLanguage from './SwitchLanguage'; // Botão de linguagem

import languageData from '../../json/language.json'; // Importe o JSON


// Definição da estrutura do JSON
interface LanguageData {
    package: {
        [key: string]: {
            menuPage: {
                myInfos: {
                    role: string;
                    sentence: string;
                };
                menu: {
                    aboutme: string;
                    experience: string;
                    portfolio: string;
                };
            };
        };
    };
}

const languageDataTyped: LanguageData = languageData;

/* MENU */

// Define as propriedades esperadas pelo componente Menu
export interface MenuProps {
    setContent: (content: string) => void; // Função para definir o conteúdo a ser exibido
    language: string; // Linguagem atual
    setLanguage: (language: string) => void; // Função para definir a linguagem
}

// Define o componente Menu como uma Function Component
const Menu: React.FunctionComponent<MenuProps> = props => {
    // Desestrutura a função setContent das propriedades recebidas
    const { setContent, language, setLanguage } = props;

    // Define um estado para armazenar o item de menu atualmente ativo
    const [activeItem, setActiveItem] = useState('aboutme');

    // Função que é chamada quando um item de menu é clicado
    const handleClick = (item: string) => {
        setContent(item); // Atualiza o conteúdo a ser exibido no componente pai
        setActiveItem(item); // Define o item clicado como o ativo
    };

    const handleLanguageChange = (lang: string) => {
        if (lang in languageDataTyped.package) {
            setLanguage(lang);
        }
    };

    const menuData = languageDataTyped.package[language].menuPage;


    // menu responsivo

    const [responsiveMenu, setResponsiveMenu] = useState(false)

    const responsiveMenuActive = (pMenu: boolean) => {
        if (pMenu === true) {
            setResponsiveMenu(false)
        } else {
            setResponsiveMenu(true)
        }
    }



    return (
        <>
            <img src={responsiveMenuIcon} alt="menuResponsive" className={`btn-responsive ${responsiveMenu === true ? 'active' : ''}`} onClick={() => { responsiveMenuActive(responsiveMenu)}} />

            <div className={`myInfos ${responsiveMenu === true ? 'hidden' : ''}`}>
                {/* Componente para trocar a linguagem */}
                <div className='language-switch'>
                    <SwitchLanguage language={language} onLanguageChange={handleLanguageChange} />
                </div>
                {/* Exibe o nome e título */}
                <div className='myName'>Giovanny Leone</div>
                <div className='title'>{menuData.myInfos.role}</div>
                <div className='sentence' dangerouslySetInnerHTML={{ __html: menuData.myInfos.sentence }}>
                    {/* Eu crio soluções escaláveis para o seu <br /> negócio. */}
                </div>

                {/* Menu de navegação */}
                <div className='menu'>
                    <ul>
                        {/* Cada item de menu chama handleClick ao ser clicado e recebe a classe 'active' se for o item ativo */}
                        <li
                            className={activeItem === 'aboutme' ? 'active' : ''}
                            onClick={() => handleClick('aboutme')}
                        >
                            {/* Sobre mim */} {menuData.menu.aboutme}
                        </li>
                        <li
                            className={activeItem === 'experience' ? 'active' : ''}
                            onClick={() => handleClick('experience')}
                        >
                            {/* Experiência */} {menuData.menu.experience}
                        </li>
                        <li
                            className={activeItem === 'portfolio' ? 'active' : ''}
                            onClick={() => handleClick('portfolio')}
                        >
                            {/* Portfolio */} {menuData.menu.portfolio}
                        </li>
                    </ul>
                </div>

                {/* Ícones de redes sociais e download de documento */}
                <div className='icons'>
                    <a href="https://github.com/GiovannyLeone" target="_blank" rel="noreferrer">
                        <img src={Github} alt="Github" />
                    </a>
                    <a href="https://www.linkedin.com/in/giovanny-leone/" target="_blank" rel="noreferrer">
                        <img src={Linkedin} alt="Linkedin" />
                    </a>
                    <a href="https://docs.google.com/document/d/1cV_2W4S1v0XS8BhMQZBKXtfCy_Z2EpmICDVxm7Oy8g0/edit?usp=sharing" target="_blank" rel="noreferrer">
                        <img src={DocDownload} alt="Doc Download" />
                    </a>
                </div>

            </div>
        </>
    );
};

export default Menu;
