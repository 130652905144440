import React from 'react';

//Styles: 
import './aboutme.modules.scss'

import languageData from '../../json/language.json'; // Importe o JSON


// Definição da estrutura do JSON
interface LanguageData {
    package: {
        [key: string]: {
            aboutMe: {
                textAboutMe: string
            };
        };
    };
}

const languageDataTyped: LanguageData = languageData;

export interface AboutmeProps {
    language: string; // Linguagem atual
};

const Aboutme: React.FunctionComponent<AboutmeProps> = props => {

    const {language } = props;

    const AboutMeData = languageDataTyped.package[language].aboutMe;

    return (
        <>
            <div className={'content'}>
                <p dangerouslySetInnerHTML={{ __html: AboutMeData.textAboutMe }}></p>
            </div>
        </>
    )
};

export default Aboutme;