import React from 'react';

//Styles: 
import './portfolio.modules.scss'

import languageData from '../../json/language.json'; // Importe o JSON

import icon_link from '../../img/icon-link.svg'

// Definição da estrutura do JSON
interface LanguageData {
    package: {
        [key: string]: {
            portfolioPage: {
                [key: string]: {
                    title: string;
                    description: string;
                    skills: {
                        [key: string]: string;
                    }
                    link: string;
                }
            }
        }
    }
}

const languageDataTyped: LanguageData = languageData;

export interface PortfolioProps {
    language: string; // Linguagem atual
};

const Portfolio: React.FunctionComponent<PortfolioProps> = props => {

    const { language } = props;

    const portfolioData = languageDataTyped.package[language].portfolioPage;

    return (
        <>
            <div className='content portfolio'>

                {/* Mapeia os dados da experiência para renderizar os elementos */}
                {portfolioData && Object.keys(portfolioData).map((key, index) => {
                    const portfolio = portfolioData[key];
                    return (
                        <div key={index}> {/* elements */}
                            <div className='title-item'>
                                <a href={portfolio.link} target='_blank' rel="noreferrer">
                                    <h1>{portfolio.title}</h1>
                                    <img src={icon_link} alt="Ícone Link" />
                                </a>
                            </div>
                            <p>{portfolio.description}</p>
                            <div className='container-skills-portfolio'>
                                {Object.values(portfolio.skills).map((skill, id_skill) => (
                                    <span key={id_skill} className='btn-skills-portfolio'>{skill}</span>
                                ))}
                            </div>
                        </div>
                    );
                })}


            </div>
        </>
    )
};

export default Portfolio;