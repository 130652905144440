import React from 'react';

//Styles: 
import './experience.modules.scss'

import languageData from '../../json/language.json'; // Importe o JSON

// Definição da estrutura do JSON
interface LanguageData {
    package: {
        [key: string]: {
            experiencePage: {
                [key: string]: {
                    time: string;
                    role: string;
                    duties: {
                        [key: string]: string;
                    }
                    skills: {
                        [key: string]: string;
                    }
                }
            }
        }
    }
}


const languageDataTyped: LanguageData = languageData;


export interface ExperienceProps {
    language: string; // Linguagem atual
};

const Experience: React.FunctionComponent<ExperienceProps> = props => {

    const { language } = props;

    const experienceData = languageDataTyped.package[language].experiencePage;

    return (
        <>
            <div className='content experience'>
                {/* Mapeia os dados da experiência para renderizar os elementos */}
                {experienceData && Object.keys(experienceData).map((key, index) => {
                    const experience = experienceData[key];
                    return (
                        <div key={index}> {/* elements */}
                            <h2>{experience.time}</h2>
                            <h1>{experience.role}</h1>
                            <ul>
                                {Object.values(experience.duties).map((duty, id_duty) => (
                                    <li key={id_duty}>{duty}</li>
                                ))}
                            </ul>
                            <div className='container-skills-experience'>
                                {Object.values(experience.skills).map((skill, id_skill) => (
                                    <span key={id_skill} className='btn-skills-experience'>{skill}</span>
                                ))}
                            </div>
                        </div>
                    );
                })}
            </div>
        </>
    )
};

export default Experience;