import React from 'react';
import './switchLanguage.modules.scss';

// Define as props esperadas pelo componente SwitchLanguage
export interface SwitchLanguageProps {
    language: string; // A linguagem atual ('br' ou 'eua')
    onLanguageChange: (lang: string) => void; // Função para mudar a linguagem
}

// Define o componente funcional SwitchLanguage
const SwitchLanguage: React.FunctionComponent<SwitchLanguageProps> = ({ language, onLanguageChange }) => {
    // Função chamada quando o switch é ativado
    const handleToggle = () => {
        // Determina a nova linguagem com base na linguagem atual
        const newLanguage = language === 'br' ? 'eua' : 'br';
        // Chama a função onLanguageChange passada como prop para atualizar a linguagem
        onLanguageChange(newLanguage);
    };

    return (
        <>
            <div className="switch-container">
                {/* Labels para as linguagens */}
                <span className={`language-label ${language === 'br' ? 'disabled' : ''}`}>EN</span>
                <span className={`language-label ${language === 'eua' ? 'disabled' : ''}`}>BR</span>
                {/* Switch para alternar entre as linguagens */}
                <label className="switch">
                    <input
                        type="checkbox"
                        hidden
                        checked={language === 'br'} // Define o estado do switch com base na linguagem atual
                        onChange={handleToggle} // Chama handleToggle quando o switch é ativado
                    />
                    <div className="switch__wrapper">
                        <div className="switch__toggle"></div>
                    </div>
                </label>
            </div>
        </>
    );
};

export default SwitchLanguage;
