import React, { useEffect, useState } from 'react';
import './App.scss';
import Pointers from '../src/img/Pointers.svg';
import Menu from './components/Menu/Menu';
import Aboutme from './components/Aboutme/Aboutme';
import Experience from './components/Experience/Experience';

//lib animation
import 'animate.css';
import Portfolio from './components/Portfolio/Portfolio';

function App() {
  // Estado para armazenar o conteúdo atual que está sendo exibido
  const [content, setContent] = useState(String);

  // Estado para armazenar o próximo conteúdo que será exibido
  const [nextContent, setNextContent] = useState(content);

  // Estado para controlar se a animação está ocorrendo
  const [isAnimating, setIsAnimating] = useState(false);

  // Estado para controlar a linguagem
  const [language, setLanguage] = useState('br');

  useEffect(() => {
    // Verifica se o próximo conteúdo é diferente do conteúdo atual
    if (nextContent !== content) {
      // Inicia a animação de saída
      setIsAnimating(true);

      // Espera a animação de saída terminar antes de trocar o conteúdo
      const timer = setTimeout(() => {
        // Atualiza o conteúdo atual para o próximo conteúdo
        setContent(nextContent);
        // Desativa a animação
        setIsAnimating(false);
      }, 1000); // duração da animação em ms

      // Limpa o timer se o componente desmontar antes do timeout
      return () => clearTimeout(timer);
    }
  }, [nextContent, content]); // Executa o efeito quando nextContent ou content mudar

  // Função para renderizar o conteúdo baseado no estado atual
  const renderContent = () => {
    switch (content) {
      case 'aboutme':
        return <Aboutme language={language} />;
      case 'experience':
        return <Experience language={language}/>;
      case 'portfolio':
        return <Portfolio language={language} />;
      default:
        return <Aboutme language={language} />;
    }
  };

  return (
    <div className="App">
      <div className="container">
        <div className='divFlex'>
          {/* Componente Menu que recebe a função setNextContent para alterar o conteúdo */}
          <Menu setContent={setNextContent} language={language} setLanguage={setLanguage} />
          {/* Div que aplica as classes de animação baseadas no estado isAnimating */}
          <div className={`${isAnimating ? 'animate__animated animate__bounceOut' : 'animate__animated animate__bounceInUp'}`}>
            {renderContent()}
          </div>
        </div>
        {/* Imagem de pontos */}
        <img src={Pointers} alt="Pontos" className='pointers' />
      </div>
    </div>
  );
}

export default App;
